:root {
  --primary: #4192b0;
  --secondary: lightblue;
  --background: #f2f3f5;
  --teritary: #9641b0;

  --colour-1: #b05f41;
  --colour-2: #5bb041;

  --gray: gray;

  --black: #010101;
  --white: #f1f1f1;
}

/* Colours */
.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.teritary {
  color: var(--teritary);
}

.background {
  color: var(--background);
}

.gray {
  color: var(--gray);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

/* Text Headings */
.extra-large {
  font-size: 40px;
}

.large {
  font-size: 30px;
}

.medium {
  font-size: 20px;
}

.normal {
  font-size: 13px;
}

.small {
  font-size: 10px;
}

/* Width */
.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

/* Cursors */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-cancel {
  cursor: not-allowed;
}

/* Margins */
.m-all {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Padding */
.p-all {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Gap */
.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

/* Text */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.b {
  font-weight: bold;
}

.b-200 {
  font-weight: 200;
}

.b-300 {
  font-weight: 300;
}

.b-400 {
  font-weight: 400;
}

.b-500 {
  font-weight: 500;
}

.b-600 {
  font-weight: 600;
}

.b-700 {
  font-weight: 700;
}

.b-800 {
  font-weight: 800;
}

.b-900 {
  font-weight: 900;
}

.row {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}

.wrap-row {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.justifyCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.justifySplit {
  justify-content: space-between;
}

.gap {
  gap: 10px;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}

/* Paddings */
.paddingAll {
  padding: 10px;
}

/* Margins */
.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

/* Gaps */
.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

/* Text */
.headerText {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
}

.a-link {
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

/* header */
.header {
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  background-color: white;
}

/* container */
.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reg-container {
  padding: 20px;
}

.inner-container {
  /* max-width: 1000px; */
  width: 100%;
  height: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.btn-grp{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 10px;
}

.right {
  justify-content: right;
}

.left {
  justify-content: left;
}

/* Button */
.button {
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  background-color: var(--primary);
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.button:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.button-cta {
  padding: 10px 20px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #aab4bf;
  background: none;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button-cta:hover {
  background-color: #aab4bf;
  color: white;
}

.button-delete {
  padding: 10px 20px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #ff0000;
  background: none;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button-delete:hover {
  background-color: #ff0000;
  color: white;
}

.button-secondary {
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  background-color: #aab4bf;
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.button-secondary:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

/* Text */
.text-primary {
  color: var(--primary);
}

.text-teritary {
  color: var(--teritary);
}

.text-secondary {
  color: var(--background);
}

/* color */
.gray {
  color: gray;
}

.red {
  color: red;
}

.white {
  color: white;
}

.black {
  color: black;
}

/* background */
.bg-primary {
  background-color: var(--primary);
}

.bg-teritary {
  background-color: var(--teritary);
}

/* Input */

.input-label {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-wrap: nowrap;
  color: #000000;
}

.text-input {
  padding: 10px;
  border: 0.5px solid var(--teritary);
  border-radius: 5px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  min-width: 0px;
}

.checkbox-input {
  padding: 10px;
  border: 1px solid var(--teritary);
  border-radius: 5px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  margin: 0;
  padding: 0;
}

.select-input {
  padding: 10px;
  border: 1px solid var(--teritary);
  border-radius: 5px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  appearance: none;
}

.select-input-chip {
  padding: 5px 10px;
  border: 1px solid var(--teritary);
  border-radius: 5px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 9px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  appearance: none;
  background-color: white;
}

.textarea-input {
  padding: 10px;
  border: 1px solid var(--teritary);
  border-radius: 5px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  resize: none;
  min-height: 100px;
}

.text-input:hover,
.textarea-input:hover {
  border: 2px solid var(--primary);
}

.backgroundLess {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

/* Chip */
.chip {
  padding: 5px 10px;
  border: 1px solid var(--teritary);
  border-radius: 50px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 9px;
  color: #000000;
  transition: all 0.2s ease-in-out;
  background-color: white;
  cursor: pointer;
}

.chip:hover {
  background-color: var(--primary);
  color: white;
}

/*  */

.link {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
}

/* navbar */
.navbar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--primary);
  padding: 10px;
  position: static;
}

.navbarItem {
  /* padding: 10px; */
  border-radius: 10px;
}

/* 
.navbarItem:hover {
    background-color: #2972ae;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
} */

.navbar-menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.1s ease-in-out;
  border-radius: 3px;
}

.navbarItemChild {
  /* move up */
  margin-bottom: 5px;
  /* padding: 10px; */
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.navbarItemChild:hover {
  /* box-shadow: 0px 0px 5px 0px var(--white) */
  padding-left: 20px;
}

.component {
  background-color: #f5f7f9;
  border-bottom: 1px solid #cfd7df;
  padding: 10px 20px;
  position: sticky;
  width: 100%;
}

.component-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.card {
  background-color: white;
  border-bottom: 1px solid #cfd7df;
  padding: 10px 20px;
  width: 100%;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
}

.card-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: var(--gray);
}

/* modal */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.blockbuilder-branding {
  display: none;
}

.header-link {
  text-decoration: none;
  color: #000000;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  gap: 5px;
}

/* File */
.file-input-container {
  transition: all 0.2s ease-in-out;
  width: 100%;
  background-color: white;
  padding: 20px 40px;
  border-radius: 20;
  border-style: dashed;
  border: 1px dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input-container.hover {
  border: 2px solid var(--primary);
  background-color: #f5f7f9;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  /* IE 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* Chrome  */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Safari and Chrome */
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
}

.search-bar::placeholder {
  color: rgb(208, 208, 208);
}

.services-button {
  background-color: #0094b3;
  transition: background-color 300ms ease-in-out;
}
.services-button:hover {
  background-color: #01768e;
}

.mobile-nav-menu,
.back-to-top-btn {
  cursor: pointer;
  fill: #4db7b3;
  transition: fill 300ms ease-in-out;
}
.mobile-nav-menu:hover,
.back-to-top-btn:hover {
  fill: #3b8f8c;
}

.mobile-nav-button {
  background-color: #4db7b3;
  transition: background-color 300ms ease-in-out;
}

.mobile-nav-button:hover {
  background-color: #3b8f8c;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
}

.footer li {
  font-size: small;
  color: #ffffff80;
  transition: color 100ms ease-in-out;
}
.footer:hover li {
  color: #ffffff;
}

.carousel-img .header-text {
  font-size: 2rem;
  color: #ffffff;
  padding: 50px;
  animation: fadeIn 3000ms ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip,
.about-tooltip {
  color: lightgray;
  position: relative;
}

.tooltip-bar {
  display: flex;
  transition-property: all;
  transition-duration: 300ms;
  transition-behavior: allow-discrete;
}

.nav-tooltip {
  position: relative;
}

.nav-tooltip .nav-tooltiptext {
  transform: translateY(50px);
  visibility: hidden;
  width: max-content;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: all 500ms;
  z-index: 99;
}
.nav-tooltiptext > li:hover {
  background-color: lightblue;
  border-radius: 3px;
  padding-inline: 10px;
  transition: all 300ms linear;
}

.sub-nav-item > a:hover {
  background-color: lightblue;
  width: fit-content;
  border-radius: 3px;
  transition: all 300ms linear;
}

.nav-tooltip:hover .nav-tooltiptext {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.tooltip:hover {
  color: #ffffff;
}

.about-tooltip:hover {
  color: black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  bottom: 100%;

  opacity: 0;
  transition: opacity 500ms;
}

.about-tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  margin-top: 10px;

  position: absolute;
  top: 100%;

  opacity: 0;
  transition: opacity 500ms;
}

.tooltip:hover .tooltiptext,
.about-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.subfooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-block: 20;
  color: #777777;
}

.header-text-name {
  font-size: 4rem;
}

.star {
  position: absolute;
  top: 0;
  bottom: 100px;
  background-color: white;
  border-radius: 50%;
  box-shadow:
    0 0 5px white,
    0 0 10px white;

  animation: animate 20s linear infinite;
}
#star-backdrop {
  transform: rotate(-25deg);
}

.about-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.vision-mission > div {
  flex: 1;
}

.vision-mission > div {
  margin-block: 2rem;
}

.about-cta {
  color: #777777;
  background-color: #ffffff;
  outline: none;
  border: 1px solid transparent;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 10px;
  text-decoration: none;
  transition: scale 300ms linear;
}

.about-cta:hover {
  scale: 1.2;
}

.about-play-video-btn {
  cursor: pointer;
  fill: #ffffff;
  transition: fill 100ms linear;
}

.about-play-video-btn:hover {
  fill: #008bad;
}

.video-iframe {
  transition: all 500ms linear;
}

.staff-members {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.staff-member {
  margin: auto;
}

.staff-member-overlay,
.staff-member-info {
  opacity: 0;
  transition: all 200ms linear;
}

.staff-member:hover > .staff-member-overlay {
  opacity: 0.8;
  mix-blend-mode: multiply;
}

.staff-member:hover > .staff-member-info {
  opacity: 1;
}

.getting-to-bmc-nav > li {
  padding: 10px 20px;
  color: #666666d9;
  cursor: pointer;
  font-weight: 600;
  border: 1px solid #ddd;
  background-color: #0000000a;
  border-top-width: 2px;
  margin-bottom: -1px;
  transition: all 200ms linear;
  flex: 1;
}

.getting-to-bmc-nav > li.active {
  color: #111111d9;
  background-color: #ffffff;
  border-top-color: #008bad;
  border-bottom: 1px solid #ffffff;
  z-index: 1;
  flex: 1.5;
}

.getting-to-bmc-info > div {
  flex: 1;
}

.online-services {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 10px;
  overflow: hidden;
}

.sermon-series-vid-link {
  text-decoration: none;
  color: #334862;
}

.sermon-series-vid-link:hover {
  color: #000000;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.online-services::after {
  z-index: -10;
  content: "";
  position: absolute;
  background-color: #eeeeee;
  margin: 0 auto;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.mission-release-cta {
  display: flex;
  gap: 10px;
}

.mission-release-cta > a {
  transition: transform 600ms linear;
}

.mission-release-cta > a:hover {
  transform: translateY(-5px);
  transition: transform 100ms linear;
}

.question {
  border-top: 1px solid #ddd;
  padding: 20px;
  cursor: pointer;
  transition: color 100ms linear;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.1rem;
}

.question::before {
  content: "\02795";
  background-color: #008bad;
  background-clip: text;
  color: transparent;
}

.question:hover {
  color: #000000;
}

.answer {
  visibility: hidden;
  opacity: 0;
  line-height: 1.5;
  font-size: 5px;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.answer.active {
  visibility: visible;
  padding: 1em 2.3em;
  font-size: 1rem;
  opacity: 1;
  height: 100%;
}

.faq:has(.answer.active) > .question {
  border-top-color: #008bad;
  color: #008bad;
  background-color: #f7f6f6;
  font-weight: bold;
}

.faq:has(.answer.active) > .question::before {
  content: "\02796";
}

.mission-release-title {
  font-size: clamp(3rem, 8vw, 7rem);
}

.contact-us-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.matthew-sermon-series-btn {
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 200ms linear;
  box-shadow: 0 4px 10px grey;
}
.matthew-sermon-series-btn:hover {
  background-color: #cccccc;
}

.sermon-series-matthew-btn {
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  background-color: #0094b3;
  transition: background-color 200ms linear;
}

.sermon-series-matthew-btn:hover {
  background-color: #016882;
}

.promo-banner {
  isolation: isolate;
  overflow: hidden;
  background: linear-gradient(#008bad, #18a3c6);
}

.promo-banner-bg-outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 650px;
  right: 0;
  transform: translateY(-200px) translateX(200px);
  /* background-color: #1894b3; */
  background-color: #18a3c6;
  clip-path: circle();
  position: absolute;
  z-index: -1;
}

.promo-banner-bg-inner-circle {
  background-color: #1894b3;
  width: 80%;
  height: 80%;
  clip-path: circle();
}
.promo-contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.promo-contact-info-name {
  font-size: clamp(2rem, 2.5vw, 4rem);
}

.promo-banner-header {
  font-size: clamp(2.4rem, 5vw, 4rem);
  font-weight: 600;
}

.promo-banner-subheader {
  font-size: clamp(1.1rem, 2.5vw, 1.8rem);
  font-weight: 600;
}

.promo-banner-desc {
  letter-spacing: 1.5px;
}

.promo-banner-download-links > div {
  max-width: 200px;
  margin-block: 10px;
}

.whatsapp-cta,
.tithes-cta,
.ministries-cta {
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
}

.new-cta {
  font-size: clamp(2rem, 10vw, 5rem);
}

.tithes-instructions {
  width: 80%;
  margin: auto;
}

.tithes-instructions > div {
  flex: 1;
  display: flex;
  justify-content: center;
}

.prayer-support {
  font-size: clamp(3rem, 5vw, 5rem);
}

table {
  border-collapse: collapse;
}
table tr td,
table th {
  border: 1px solid gray;
}

table th {
  font-weight: 600;
}

.desktop-nav {
  display: flex;
}

.mobile-nav {
  display: none;
}

.ministries-nav {
  color: #666666;
  font-weight: 600;
}

.ministries-nav > h2 {
  position: relative;
  cursor: pointer;
  transition: all 300ms linear;
}

.ministries-nav > h2:hover,
.ministries-nav > .active {
  color: #010101;
}

.ministries-nav > h2::before {
  content: "";
  position: absolute;
  opacity: 0;
  background-color: rgb(0, 148, 179);
  width: 100%;
  height: 3px;
  bottom: -5px;
  transition: all 300ms;
}

.ministries-nav > h2:hover::before,
.ministries-nav > .active::before {
  opacity: 1;
}

.ministries-photowall {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.ministry-photo {
  overflow: hidden;
}

.ministry-photo {
  animation: popIn 500ms forwards ease-in;
}

.featured-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@keyframes popIn {
  from {
    opacity: 0;
    scale: 0.2;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.ministry-photo > img,
.ministry-photo > .ministry-desc {
  transition: all 500ms;
}

.ministry-photo:hover > img {
  scale: 1.1;
}
.ministry-desc {
  opacity: 0;
  visibility: hidden;
}
.ministry-photo:hover > .ministry-desc {
  visibility: visible;
  opacity: 1;
}

.mobile-nav-item {
  color: #ffffff;
  transition: color 300ms;
}
.mobile-nav-item:hover {
  color: #235857;
}

.sub-nav-item {
  display: none;
}

.main-nav-item:hover > .sub-nav-item {
  background-color: #ffffff;
  position: absolute;
  display: block;
  transform: translateX(70%);
  padding-inline: 10px;
  top: 48%;
  border-radius: 0 10px 10px 0;
  width: 150px;
  z-index: -1;
  padding-left: 15px;
}

.read-more-btn {
  background-color: #0099ab;
  transition: background-color 300ms;
}

.read-more-btn:hover {
  background-color: #007683;
}

.not-found-header {
  background: url("../public/About_CTA_Banner.jpeg");
  background-position: 51% 50%;
  background-clip: text;
  color: transparent;
  font-size: clamp(3rem, 30vw, 7rem);
  font-weight: 600;
  letter-spacing: 1.5px;
}

@media (max-width: 941px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    display: flex;
  }
  .tooltip-bar {
    display: none;
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template: 1fr / 1fr;
  }
  .subfooter {
    grid-template-columns: 1fr;
  }
  .header-text {
    font-size: 1rem;
  }
  .header-text-name {
    font-size: 2rem;
  }
  .about-cards {
    grid-template-columns: 1fr;
  }
  .about-history-info {
    margin-top: 20px;
  }
  .staff-members {
    grid-template-columns: 1fr 1fr;
  }
  .online-services::before {
    z-index: -10;
    content: "";
    position: absolute;
    top: 50%;
    left: -95%;
    width: 300%;
    height: 30%;
    background: linear-gradient(90deg, #035472 50%, #008bad 50%);

    animation: rotate 8s linear infinite;
  }
  .mission-release-cta {
    flex-direction: column;
  }
  .promo-banner-main-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* align-items: center; */
  }
  .promo-banner-qr {
    order: 3;
    margin: auto;
  }
  .promo-contact-info-name {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  .user-form-section {
    margin-top: 20px;
  }
  .featured-item-hr {
    display: none;
  }
}

@media (min-width: 768px) {
  .about-cards:has(> div:hover) > :not(div:hover) {
    filter: grayscale();
  }
  .about-cards:has(> div:hover) > div:hover {
    z-index: 1;
    scale: 1.2;
  }
  .about-cards > div {
    transition: all 500ms linear;
    cursor: pointer;
  }
  .vision-mission {
    display: flex;
  }
  .about-history {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .about-video-title {
    font-size: 1.2rem;
  }
  .about-video-desc {
    font-size: 3rem;
  }
  .getting-to-bmc-info {
    display: flex;
    gap: 10px;
  }
  .online-services {
    flex-direction: row;
    gap: 20px;
  }
  .online-services::before {
    z-index: -10;
    content: "";
    position: absolute;
    top: 25%;
    left: -25%;
    width: 150%;
    height: 60%;
    background: linear-gradient(90deg, #035472 0%, #008bad 100%);

    animation: rotate 8s linear infinite;
  }
  .contact-us-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .promo-contact-info {
    flex-direction: row;
    justify-content: space-around;
    align-items: end;
  }
  .promo-contact-info-name-suffix {
    display: block;
  }
  .promo-banner-main-content {
    display: grid;
    grid-template-columns: 0.5fr 0.3fr 1fr;
  }
  .promo-banner-download-links {
    display: flex;
    gap: 10px;
  }
  .promo-banner-qr {
    align-self: end;
    justify-self: center;
  }
  .mode-of-contact {
    display: flex;
  }
  .tithes-instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .new-user-form {
    display: flex;
    gap: 10px;
  }
  .featured-item {
    flex-direction: row;
  }
}

@media (max-width: 425px) {
  .header-text {
    font-size: 0.5rem;
  }
  .header-text-name {
    font-size: 1rem;
  }
  .staff-members {
    grid-template-columns: 1fr;
  }

  .mission-release-title {
    font-size: 40px;
  }
  .quick-links > div > a {
    font-size: 0.6rem;
  }
}

.getting-to-bmc-nav > li {
  font-size: clamp(0.6rem, 2.5vw, 1rem);
}

@keyframes animate {
  0% {
    transform: translateY(100dvh);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}


*:disabled {
  cursor: not-allowed;
  background-color: #f5f7f9;
}